import { render, staticRenderFns } from "./system-backup-table.vue?vue&type=template&id=6dedd5ba&scoped=true&"
import script from "./system-backup-table.vue?vue&type=script&lang=js&"
export * from "./system-backup-table.vue?vue&type=script&lang=js&"
import style0 from "./system-backup-table.vue?vue&type=style&index=0&lang=css&"
import style1 from "./system-backup-table.vue?vue&type=style&index=1&id=6dedd5ba&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6dedd5ba",
  null
  
)

export default component.exports