<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style type="text/css" scoped>
 .sys-table-input{
   height: 25px;
   margin-top: 0;
 }
 /deep/.el-input--small .el-input__inner {
   height: 30px;
 }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import BackupHeader from "./backup-header.vue";
  import {
    tableList,
    backupDbTable,
    backupTableListExport
  } from "@/api/log/log.js";

  /**
   * 系统设置--数据表备份
   */
  export default {
    components: {
      Layout,
      BackupHeader,



    },
    data() {
      return {
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "系统管理",
            active: true
          }
        ],
        query:{
          pageNum: 1,
          pageSize: 20
        },
        list: [],
        // 当前页
        currentPage: 1,
        // 每页显示
        perPage: 20,
        // 总数
        total: 0
      };
    },
    created() {
      this.getList();

    },
    computed: {
      rows() {
        return this.list.length;
      },
    },
    methods: {
      /** 查询数据库表名列表 */
      getList() {
        this.query.pageNum = this.currentPage
        this.query.pageSize = this.perPage
        tableList(this.query).then(res => {
          if (res.status) {
            this.list = res.data
            this.total = res.total;
          }
        });
      },
      
      /** 失去焦点修改input中的值 */
      blurChange(row, type, event) {
        if (type == 'name') {
          row.fileName = event.target.value
        } else {
          row.memo = event.target.value
        }
      },
      /** 修改参数值 */
      handleUpdate(row) {
        if (row.level == null) {
          this.$message({
            type: 'error',
            message: "请选择一个备份等级"
          });
          return;
        }
        let param = {
          tablename: row.TABLE_NAME,
          bakupPath: row.fileName,
          tableMemo: row.memo,
          important: row.level,
          canEmpty: row.canEmpty?1:0
        }
        this.$confirm('是否确认备份【'+row.TABLE_NAME+'】 这将花费一定的时间，是否继续?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return backupDbTable(param);
        }).then(() => {
          this.$message({
            type: 'success',
            message: "备份成功"
          });
        })
      },
      exportExcel() {
        backupTableListExport(this.query).then(res => {
          if (!res.data) {
            return
          }
          let url = window.URL.createObjectURL(new Blob([res.data]))
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.setAttribute('download', '数据库表备份日志.xlsx')

          document.body.appendChild(link)
          link.click()
        })
      },
      handleSizeChange(val) {
        this.perPage = val
        this.getList();
      },
      handleCurrentChange(val) {
        this.currentPage = val
        this.getList();
      },
    }
  };
</script>

<template>
  <Layout>
    <BackupHeader :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 500px;">
          <div class="card-body">

            <div class="table-responsive border">
              <table class="table light-table table-hover  table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 4%;"> 序号</th>
                    <th style="width: 15%;">数据表</th>
                    <th style="width: 15%;">表说明</th>
                    <th style="width: 13%;">备份等级 </th>
                    <th style="width: 7%;">是否允许清空 </th>
                    <th>备份名称 </th>
                    <th>备份操作说明 </th>
                    <th style="width: 5%;">操作</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(obj, index) in list" :key="index">
                    <td>{{index+1+(currentPage-1)*perPage}}</td>
                    <td>{{obj.TABLE_NAME}}</td>
                    <td>{{obj.TABLE_COMMENT}}</td>
                    <td>
                      <el-select class=" mr-2 blueBorder" style="width: 100%;" placeholder="请选择备份等级" v-model="obj.level"
                                 size="small">
                        <el-option value="普通" label="普通" selected>普通</el-option>
                        <el-option value="重要" label="重要">重要</el-option>
                        <el-option value="非常重要" label="非常重要">非常重要</el-option>
                      </el-select>
                    </td>
                    <td>
                      <b-form-checkbox v-model="obj.can_empty" switch class=" switch-check">
                      </b-form-checkbox>
                    </td>
                    <td><input type="" :value="obj.paramValue" @blur="blurChange(obj, 'name', $event)" placeholder="输入备份名称，不填则自动命名"
                               class="form-control sys-table-input mr-2" /></td>
                    <td><input type="" :value="obj.paramValue" @blur="blurChange(obj, 'memo', $event)" placeholder="输入备份说明"
                               class="form-control sys-table-input mr-2" /> </td>
                    <td><button type="button" class="btn btn-info h30  m-55" @click="handleUpdate(obj)">备份</button></td>
                  </tr>


                  <!--<tr>-->
                    <!--<td> 1 </td>-->
                    <!--<td>data_register_now </td>-->
                    <!--<td>系统报名(正在使用)</td>-->
                    <!--<td>非常重要 </td>-->
                    <!--<td>否</td>-->
                    <!--<td><input type="" name="" id="" value="data_register_now20211217"-->
                      <!--class="form-control sys-table-input mr-2" /></td>-->
                    <!--<td><input type="" name="" id="" value="定期常规备份"-->
                      <!--class="form-control sys-table-input mr-2" /> </td>-->
                    <!--<td><button type="button" class="btn btn-info h30  m-55">备份</button></td>-->
                  <!--</tr>-->
                  <!--<tr>-->
                    <!--<td> 1 </td>-->
                    <!--<td>data_register_now </td>-->
                    <!--<td>系统报名(正在使用)</td>-->
                    <!--<td>非常重要 </td>-->
                    <!--<td>否</td>-->
                    <!--<td><input type="" name="" id="" value="data_register_now20211217"-->
                      <!--class="form-control sys-table-input mr-2" /></td>-->
                    <!--<td> <input type="" name="" id="" value="定期常规备份"-->
                      <!--class="form-control sys-table-input mr-2" /> </td>-->
                    <!--<td><button type="button" class="btn btn-info h30 m-55">备份</button></td>-->
                  <!--</tr>-->
                  <!--<tr>-->
                    <!--<td> 1 </td>-->
                    <!--<td>data_register_now </td>-->
                    <!--<td>系统报名(正在使用)</td>-->
                    <!--<td>非常重要 </td>-->
                    <!--<td>否</td>-->
                    <!--<td><input type="" name="" id="" value="data_register_now20211217"-->
                      <!--class="form-control sys-table-input mr-2" /></td>-->
                    <!--<td> <input type="" name="" id="" value="定期常规备份"-->
                      <!--class="form-control sys-table-input mr-2" /> </td>-->
                    <!--<td><button type="button" class="btn btn-info h30 m-55">备份</button></td>-->
                  <!--</tr>-->
                </tbody>
              </table>
            </div>
            <div class="float-right d-flex">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                             :current-page.sync="currentPage" :page-size="perPage"
                             layout="total, sizes,jumper, prev, pager, next" :total="total">
              </el-pagination>
            </div>

          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->


    <!-- 弹窗结束 -->
  </Layout>
</template>
