<style type="text/css">
  @import "~@/assets/css/table.css";
</style>
<script>
  export default {
    components: {},
    props: {
      items: {
        type: Array,
        default: () => {
          return []
        },
      },
    },
    data(){
      return{
        isActive:1,
        pathArr:[
          {
            index:1,
            url:"/admin/systemBackup",
            name:"数据库备份",
          },
          {
            index:2,
            url:"/admin/systemBackupTable",
            name:"表备份",
          },]
      }
    },
    mounted(){
      this.isActive=window.location.pathname
      
    }
  }
</script>

<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class=" flexList justify-content-between">
        <div class="pb-3  check-table-top">
          <div class="flexList">
          <div class="backup-top" :class="{active:isActive==obj.url}" v-for="obj in pathArr" :key="obj.index"><a :href="obj.url">{{obj.name}}</a></div>
          </div>

        </div>
        <div class="page-title-right">
          <b-breadcrumb :items="items" class="m-0"></b-breadcrumb>
        </div>
      </div>
    </div>
  </div>
  <!-- end page title -->
</template>
